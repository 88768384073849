import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { AuthProvider } from './context/auth';
import routes from './routes/routes';
import AppRoute from './routes/appRoutes';
import './styles/app.css';
import Navbar from './pages/Navbar/Navbar';
import { SocketProvider } from './context/SocketProvider';
import useIFramed from './hooks/useIFrame';
import DevLocal from './components/Development/DevLocal';

function App() {
	const isIFramed = useIFramed();
	return (
		<div
			className={isIFramed ? 'app__container-iframed' : 'app__container'}
		>
			<AuthProvider>
				<SocketProvider>
					<BrowserRouter>
						{process.env.REACT_APP_ENVIRONMENT ===
							'DEVELOPMENT' && <DevLocal />}
						<Navbar />
						<Switch>
							{routes.map((route) => (
								<AppRoute
									key={route.path}
									path={route.path}
									component={route.component}
									isPrivate={route.isPrivate}
								/>
							))}
						</Switch>
					</BrowserRouter>
				</SocketProvider>
			</AuthProvider>
		</div>
	);
}

export default App;
